import * as Sentry from '@sentry/nextjs'
import { useStatsigClient } from '@statsig/react-bindings'
import useFlightSalesPoliciesExperiment from './useFlightSalesPoliciesExperiment'

export default function useLogEvent() {
    const { logEvent } = useStatsigClient()
    const salesPolicies = useFlightSalesPoliciesExperiment()

    return (eventName, metaData, journeyUid) => {
        try {
            // Data layer
            if (window.dataLayer) {
                window.dataLayer.push({
                    engine: 'new',
                    salesPolicies,
                    event: eventName,
                    pageTitle: document.title,
                    pagePath: window.location.href,
                    ...metaData,
                })
            }
            // Statsig
            logEvent(eventName, journeyUid, { salesPolicies, ...metaData })
        } catch (e) {
            Sentry.captureException(e)
        }
    }
}
